
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Pie } from 'vue-chartjs';
import {
  Query, PartialQuery, QueryResult, QueryResultCell,
} from '@/store/modules/query/types';
import resolveLabel from '@/utils/I18nLabel';
import { getAxisXColor } from '@/utils/graphHelper';
import Chart from 'chart.js';
import Log from '@/utils/log';
import { AggregatedMode } from '../store/modules/mode-activity/types';
import query from '../api/query';

@Component({
  props: {
    queryResult: {},
    query: {},
    customPalette: {},
  },
})
export default class ResultPieChartComponent extends mixins(Pie) {
  mounted() {
    const queryResult : QueryResult = this.$props.queryResult;
    const datasets : Array<any> = [];
    const labels : Array<string> = [];
    const textColor : Array<any> = [];
    for (let iy = 0; iy < queryResult.axisY.length; iy += 1) {
      datasets.push({
        data: [],
        n: [], // custom prop to store n individuals
        backgroundColor: [],
        share: [], // share of 1.0 (percentage / 100)
        dataLabel: [], // datalabel text
      });
    }
    const cells = queryResult.cells;
    for (let ix = 0; ix < cells.length; ix += 1) {
      const l = resolveLabel(queryResult.axisX[ix].label, queryResult.axisX[ix].labelTranslations);
      const color = getAxisXColor(
        ix, queryResult, this.$props.query, this.$store.state.modeActivity, this.$props.customPalette,
      );
      for (let iy = 0; iy < cells[ix].length; iy += 1) {
        datasets[iy].data.push(cells[ix][iy].value);
        datasets[iy].n.push(cells[ix][iy].n);
        datasets[iy].backgroundColor.push(color.bgColor);
        textColor.push(color.textColor);
      }
      labels.push(l);
    }
    // build datalabel texts
    if (cells.length > 0) {
      for (let iy = 0; iy < cells[0].length; iy += 1) {
        let sum = 0;
        for (let ix = 0; ix < cells.length; ix += 1) {
          sum += cells[ix][iy].value;
        }
        for (let ix = 0; ix < cells.length; ix += 1) {
          /* eslint no-mixed-operators: ["off", {}}] */
          datasets[iy].share.push(sum > 0 ? cells[ix][iy].value / sum : 0);
          datasets[iy].dataLabel.push(sum > 0 ? `${Math.round(cells[ix][iy].value * 100 / sum)} %` : '');
        }
      }
    }
    const dataParam = {
      label: '',
      labels,
      datasets,
    };
    Log.log(dataParam);
    const options : Chart.ChartOptions = {
      // make a dougnut because it renders small classes better
      cutoutPercentage: 70,
      animation: {
        // The pie chart animation is too heavy on my high-end workbook,
        // so it is likely to heavy for most users too.
        duration: 0,
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'center', // 'start', 'center' or 'end'
        onClick: () => {},
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem : any, data : any) => {
            Log.log(tooltipItem);
            let label = data.labels[tooltipItem.index] || '';
            if (label.length > 0) label += ': ';
            label += datasets[tooltipItem.datasetIndex].dataLabel[tooltipItem.index];
            label += ` (n: ${datasets[tooltipItem.datasetIndex].n[tooltipItem.index]})`;
            return label;
          },
        },
      },
      plugins: {
        datalabels: {
          formatter:
            (value, context) => datasets[context.datasetIndex].dataLabel[context.dataIndex],
          color: (context) => textColor[context.dataIndex],
          display: (context) => datasets[context.datasetIndex].share[context.dataIndex] > 0.03,
        },
      },
    };
    this.renderChart(dataParam, options);
  }
}
