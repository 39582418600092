import { PartialQuery, Query, QueryResult } from '@/store/modules/query/types';
import { FilterOverride } from '@/store/modules/result-board/types';
import { RootState } from '@/store/root-state';
import { Store } from 'vuex';
import Log from './log';
import timeout from './timeout';

export default class QueryRequester {
  /* eslint no-underscore-dangle: ["off", {}}] */
  private $store: Store<RootState> | null = null;
  private _sendId: number | null = null;
  private _result: QueryResult | null = null;
  private _query: Query | null = null;
  private _loadError: boolean = false;

  constructor(store: Store<RootState>) {
    this.$store = store;
  }

  get result(): QueryResult | null { return this._result; }
  get sendId(): number | null { return this._sendId; }
  get query(): Query | null { return this._query; }
  get loadError(): boolean { return this._loadError; }

  /**
   * Fetch QueryResult for the given partial query
   * and current survey, filter state and querySettings.
   *
   * During query sendId getter will be non-null and when query
   * is completed it is set back to null and result is updated.
   *
   * @param query The query to make
   * @returns promise that resolves into a QueryResult
   */
  request(partialQuery: PartialQuery, filterOverride: FilterOverride): Promise<QueryResult> {
    return new Promise<QueryResult>(async (resolve, reject) => {
      if (process.env.VUE_APP_NO_SERVER === '1') {
        Log.log('Simulate waiting on server');
        await timeout(1000);
        throw new Error('Not supported by VUE_APP_NO_SERVER=1 mode');
      }

      try {
        Log.log('request');
        if (this.$store!.state.modeActivity.aggregatedModes.length === 0
          || this.$store!.state.modeActivity.aggregatedActivities.length === 0
        ) {
          this._loadError = true;
          reject();
          return;
        }

        this._loadError = false;
        this._result = null;
        this._query = null;

        const query = QueryRequester.resolveQuery(this.$store!, partialQuery, filterOverride);
        if (query === null) {
          this._loadError = true;
          reject();
          return;
        }
        Log.log('query:', query);

        const sameQueryId = this.$store!.getters['query/findSameQuery'](query);
        const sendId = sameQueryId !== null
          ? sameQueryId : await this.$store!.dispatch('query/sendQuery', { query });
        this._sendId = sendId;
        const result: QueryResult = await this.$store!.dispatch('query/waitOnQuery', { sendId: this._sendId });
        Log.log('result', result);
        if (this._sendId === sendId) {
          // Only assign result if there was not a new query sent while waiting for
          // the response
          this._result = result;
          this._query = query;
          this._sendId = null;
          resolve(result);
        } else {
          // Resolve but don't update result/sendId if a new request was made
          resolve(result);
        }
      } catch (e) {
        Log.log(e);
        this._sendId = null;
        this._result = null;
        this._query = null;
        this._loadError = true;
        reject();
      }
    });
  }

  clearResult() {
    this._result = null;
  }

  /**
   * Resolve full Query from given partialQuery and looking up all
   * other parameters from the store inculding surveyId, filters, querySettings etc.
   * @param store
   * @param partialQuery
   * @returns
   */
  static resolveQuery(store: Store<RootState>, partialQuery: PartialQuery, filterOverride: FilterOverride) {
    const survey = store.getters['survey/getSelected'];
    if (survey === null) return null;
    const filter = {
      ...store.state.filter,
    };
    // Apply overrides that has a non-undefined value.
    Object.keys(filterOverride).forEach((key) => {
      if ((filterOverride as any)[key] !== undefined) {
        (filter as any)[key] = (filterOverride as any)[key];
      }
    });
    const query: Query = Object.assign(partialQuery, {
      surveyId: survey.id,
      filter,
      includeJoinDateInSurvey: store.state.querySettings.includeJoinDateInSurvey,
      limits: store.state.querySettings.limits,
      minCellN: store.state.querySettings.minCellN,
      weightLimits: store.state.querySettings.weightLimits,
      aggregatedModeConfig: store.state.modeActivity.aggregatedModes,
      aggregatedActivityConfig: store.state.modeActivity.aggregatedActivities,
      activityMethod: store.state.querySettings.activityMethod,
      nRequiresTrips: store.state.querySettings.nRequiresTrips,
      vtiReselementMerge: store.state.querySettings.vtiReselementMerge,
    });
    return query;
  }
}
