

















import { Component, Vue } from 'vue-property-decorator';
import Unwatcher from '@/utils/unwatcher';
import QueryRequester from '@/utils/queryRequester';
import Log from '@/utils/log';
import { Survey } from '@/store/modules/survey/types';
import { formatYmdString } from '@/utils/dateHelpers';
import {
  Denominator,
  Numerator,
  PartialQuery,
  QueryResult,
  StatMethod,
  TravelType,
} from '@/store/modules/query/types';
import { formatValue } from '@/utils/graphHelper';
import { FilterOverride, ResultFormatter } from '@/store/modules/result-board/types';
import i18n from '@/i18n';
import { Store } from 'vuex';
import { RootState } from '@/store/root-state';

interface DescriptionRow {
  label : string;
  partialQuery : PartialQuery;
  format : ResultFormatter|null;
  requester : QueryRequester;
  useWithNRequestTrips : boolean;
}

@Component({
  props: {
  },
  components: {
  },
})
export default class DataSetDescriptionComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  dataSetDescription : string|null = null;
  filteredDataDescription : string|null = null;
  descData : Array<DescriptionRow> = [];

  data() {
    const selectedSurvey : Survey|null = this.$store.getters['survey/getSelected'];
    let dataSetDescription = '';
    if (selectedSurvey !== null) {
      const start = formatYmdString(
        selectedSurvey.startDate, this.$t('analysis.no-start-date').toString(), this.$i18n.locale,
      );
      const end = formatYmdString(
        selectedSurvey.endDate, this.$t('analysis.no-end-date').toString(), this.$i18n.locale,
      );
      dataSetDescription += `Survey: ${selectedSurvey.label}\n`;
      dataSetDescription += `Collection period: ${start} - ${end}\n\n`;
    }

    const { resultBoards } = this.$store.state.resultBoard;
    Log.log(resultBoards);

    return {
      dataSetDescription,
      filteredDataDescription: '',
    };
  }

  private initDescData() {
    // The first row in descData that returns results will be used to output n individuals
    this.descData = [
      {
        label: 'analysis.filtered-data.n-corrected',
        useWithNRequestTrips: false,
        partialQuery: {
          axisX: '',
          axisY: '',
          numerator: Numerator.nCorrectedDay,
          denominator: Denominator.one,
          statMethod: StatMethod.estimatingMean,
          travelType: TravelType.delresa,
          enableWeighting: false,
          zoneFile: null,
          shareOutput: false,
          calculateMapFilter: false,
        } as PartialQuery,
        format: null,
        requester: new QueryRequester(this.$store),
      },
      {
        label: 'analysis.filtered-data.n-trips',
        useWithNRequestTrips: true,
        partialQuery: {
          axisX: '',
          axisY: '',
          numerator: Numerator.nDelresa,
          denominator: Denominator.one,
          statMethod: StatMethod.estimatingMean,
          travelType: TravelType.delresa,
          enableWeighting: false,
          zoneFile: null,
          shareOutput: false,
          calculateMapFilter: false,
        } as PartialQuery,
        format: null,
        requester: new QueryRequester(this.$store),
      },
      {
        label: 'analysis.filtered-data.duration',
        useWithNRequestTrips: true,
        partialQuery: {
          axisX: '',
          axisY: '',
          numerator: Numerator.delresaDurationS,
          denominator: Denominator.one,
          statMethod: StatMethod.estimatingMean,
          travelType: TravelType.delresa,
          enableWeighting: false,
          zoneFile: null,
          shareOutput: false,
          calculateMapFilter: false,
        } as PartialQuery,
        format: ResultFormatter.sToHours,
        requester: new QueryRequester(this.$store),
      },
      {
        label: 'analysis.filtered-data.distance',
        useWithNRequestTrips: true,
        partialQuery: {
          axisX: '',
          axisY: '',
          numerator: Numerator.delresaDistanceM,
          denominator: Denominator.one,
          statMethod: StatMethod.estimatingMean,
          travelType: TravelType.delresa,
          enableWeighting: false,
          zoneFile: null,
          shareOutput: false,
          calculateMapFilter: false,
        } as PartialQuery,
        format: ResultFormatter.mToKm,
        requester: new QueryRequester(this.$store),
      },
    ];
  }

  public async created() {
    this.initDescData();

    const self = this;
    const watchChangedFn = (
      source : string, newStoreValue: Array<number>|null, oldStoreValue: Array<number>|null,
    ) => {
      if (newStoreValue !== oldStoreValue) {
        Log.log(`${source} change => fetch`);
        self.fetch().catch(() => {});
      }
    };

    // When filter state changes => new fetch
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => getters['filter/filterState'],
      watchChangedFn.bind(this, 'filter'),
    ));

    // When aggregation state changes => new fetch
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => getters['modeActivity/aggregatedState'],
      watchChangedFn.bind(this, 'aggregated modes or activities'),
    ));

    // When query-settings state changes => new fetch
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => getters['querySettings/querySettingsState'],
      watchChangedFn.bind(this, 'query settings'),
    ));

    // Initial fetch of data
    // Don't perform initial fetch if filter has not been initialized yet
    if (self.$store.state.filter.aggregatedModes.length > 0
      && self.$store.state.filter.aggregatedActivities.length > 0
    ) {
      Log.log('initial load (with filters loaded) => fetch');
      self.fetch().catch(() => {});
    }
  }

  beforeDestroy() {
    this.unwatcher.unwatchAll();
  }

  async fetch() {
    Log.log('Fetch desc data');
    const survey = this.$store.getters['survey/getSelected'];
    if (survey === null) {
      return;
    }

    this.filteredDataDescription = ''; // invalidate current data

    const waitOn : Array<Promise<QueryResult>> = [];
    const nRequiresTrips = (this.$store as Store<RootState>).state.querySettings.nRequiresTrips;
    const noFilterOverrides: FilterOverride = {
      aggregatedModes: undefined,
    };
    this.descData.forEach((row) => {
      row.requester.clearResult(); // invalidate result
      if (nRequiresTrips === false || row.useWithNRequestTrips) {
        waitOn.push(row.requester.request(row.partialQuery, noFilterOverrides));
      }
    });

    await Promise.allSettled(waitOn);

    Log.log('Got desc data');
    let s = '';

    // Use first descData item with results to grab N individuals
    const firstResultRow = this.descData.find((row) => row.requester.result !== null);
    if (firstResultRow !== null) {
      const n = formatValue(firstResultRow!.requester.result!.cells[0][0].n, null, 0);
      s += this.$t('analysis.filtered-data.n-individuals', { value: n });
      s += '\n';
    }

    this.descData.forEach((row) => {
      const result = row.requester.result;
      if (result !== null) {
        const value = formatValue(result.cells[0][0].value, row.format, 0);
        s += this.$t(row.label, { value });
        if (row.format === ResultFormatter.mToKm) {
          const earthLaps = result.cells[0][0].value / 1000 / 40075.0;
          if (earthLaps >= 1) {
            s += ' (';
            s += this.$t('analysis.filtered-data.earth-laps', {
              value: formatValue(earthLaps, null, 0),
            });
            s += ')';
          }
        }
        s += '\n';
      } else {
        const value = nRequiresTrips && !row.useWithNRequestTrips
          ? (this.$t('resultbox.not-available') as any).toLocaleLowerCase()
          : (this.$t('resultbox.load-error') as any).toLocaleLowerCase();
        s += this.$t(row.label, { value });
        s += '\n';
      }
    });

    this.filteredDataDescription = s;
  }

  onReload() {
    this.fetch().catch(() => {});
  }
}
