




































































import { Component, Vue } from 'vue-property-decorator';
import {
  Denominator, MapFilter, Numerator, StatMethod, TravelType,
} from '@/store/modules/query/types';
import QueryRequester from '@/utils/queryRequester';
import Unwatcher from '@/utils/unwatcher';
import { watchStoreResendResultQuery } from '@/store/watch';
import { Survey } from '@/store/modules/survey/types';
import { FlavorData } from '@/flavor/types';
import { flavorData } from '@/flavor';
import MapMercartorComponent, { MapStyle } from './MapMercartorComponent.vue';
import MapLegendComponent from './MapLegendComponent.vue';

@Component({
  props: {
  },
  components: {
    MapMercartorComponent,
    MapLegendComponent,
  },
})
export default class MapResultComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  requester = new QueryRequester(this.$store);
  hasMapData : boolean = false;
  mapFilter : MapFilter|null = null;
  mapStyle = MapStyle.app;
  mapStyles: Array<MapStyle> = [];
  mapStyleLabels: any = {};
  flavorData : FlavorData = flavorData;

  // eslint-disable-next-line class-methods-use-this
  public data() {
    const survey : Survey|null = this.$store.getters['survey/getSelected'];
    const mapStyleLabels: any = {};
    mapStyleLabels[MapStyle.app] = this.$i18n.tc('map-style.app');
    mapStyleLabels[MapStyle.light] = this.$i18n.tc('map-style.light');
    mapStyleLabels[MapStyle.dark] = this.$i18n.tc('map-style.dark');
    mapStyleLabels[MapStyle.transparent] = this.$i18n.tc('map-style.transparent');
    return {
      hasMapData: survey?.hasMapData === true,
      mapStyles: [
        MapStyle.app,
        MapStyle.dark,
        MapStyle.transparent,
      ],
      mapStyleLabels,
    };
  }

  public async created() {
    watchStoreResendResultQuery(
      this.$store,
      this.unwatcher,
      (source: string) => this.fetch(),
    );
    this.fetch();
  }

  beforeDestroy() {
    this.unwatcher.unwatchAll();
  }

  private async fetch() {
    this.mapFilter = null;
    if (!this.hasMapData) {
      return;
    }
    await this.requester.request({
      calculateMapFilter: true,
      axisX: '',
      axisY: '',
      numerator: Numerator.nReselement,
      denominator: Denominator.anyDayDivN,
      scaleFactor: null,
      statMethod: StatMethod.estimatingProportion,
      travelType: TravelType.reselement,
      enableWeighting: true,
      zoneFile: null,
      shareOutput: false,
    }, {
      aggregatedModes: undefined,
    });
    // mapFilter in result is defined as nullable only, but arrives from server so guard against undefined.
    this.mapFilter = this.requester.result!.mapFilter ?? null;
  }

  private onReload() {
    this.fetch();
  }
}
