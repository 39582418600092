
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { HorizontalBar } from 'vue-chartjs';
import { Query, QueryResult, Axis } from '@/store/modules/query/types';
import { Context } from 'chartjs-plugin-datalabels';
import resolveLabel from '@/utils/I18nLabel';
import {
  getAxisXColor,
  scaleValue,
  formatScaledValue,
  formatValue,
  getValueUnitLabel,
} from '@/utils/graphHelper';
import i18n from '@/i18n';
import Chart from 'chart.js';
import { AggregatedMode } from '../store/modules/mode-activity/types';
import { ResultBox, ResultFormatter } from '../store/modules/result-board/types';

@Component({
  props: {
    queryResult: {},
    query: {},
    box: {},
  },
})

export default class ResultHorizontalBarChartComponent extends mixins(HorizontalBar) {
  mounted() {
    const box : ResultBox = this.$props.box;
    const queryResult : QueryResult = this.$props.queryResult;
    const query : Query = this.$props.query;
    const datasets : Array<any> = [];
    const xLabelsFull : Array<string> = [];
    const xLabelsShort : Array<string> = [];
    const yLabels : Array<string> = [];
    const textColor : Array<any> = [];
    const ySums : Array<number> = []; // Sum of cells along x-axis for each y index

    for (let ix = 0; ix < queryResult.axisX.length; ix += 1) {
      datasets.push({
        data: [],
        share: [],
        isSum: [],
        backgroundColor: [],
        label: resolveLabel(queryResult.axisX[ix].label, queryResult.axisX[ix].labelTranslations),
      });
    }

    const cells = queryResult.cells;
    for (let iy = 0; iy < queryResult.axisY.length; iy += 1) {
      yLabels.push(resolveLabel(queryResult.axisY[iy].label, queryResult.axisY[iy].labelTranslations));
      ySums.push(0);
      for (let ix = 0; ix < queryResult.axisX.length; ix += 1) {
        ySums[iy] += cells[ix][iy].value;
      }
    }

    for (let ix = 0; ix < cells.length; ix += 1) {
      const color = getAxisXColor(ix, queryResult, query, this.$store.state.modeActivity, box.customPalette);
      const l = resolveLabel(queryResult.axisX[ix].label, queryResult.axisX[ix].labelTranslations);
      let lShort = l;
      if (l.length > 20) lShort = `${l.substr(0, 17)}...`;
      xLabelsFull.push(l);
      xLabelsShort.push(lShort);
      for (let iy = 0; iy < cells[ix].length; iy += 1) {
        const val = cells[ix][iy].value;
        datasets[ix].data.push(scaleValue(val, box.valueFormatter));
        const share = ySums[iy] > 0
          ? val / ySums[iy]
          : null;
        if (share === null || share < 0.05) { // If the share of the total sum is less than 5 percent, we dont show the value to keep the graph less cluttered
          datasets[ix].share.push(null);
        } else {
          datasets[ix].share.push(val / ySums[iy]);
        }
        datasets[ix].isSum.push(false);
        datasets[ix].backgroundColor.push(color.bgColor);
      }
      textColor.push(color.textColor);
    }
    const xAxisMax = ySums.reduce((carry, value) => Math.max(carry, value), 0.0);

    const showXSum = query.shareOutput !== Axis.x;
    if (showXSum) {
      // Add a item farmost on the x-axis with value 0
      // for the purpose of showing the sum to the right of the
      // visible stacked bar.
      datasets.push({
        data: [],
        share: [],
        isSum: [],
        backgroundColor: [],
        label: '',
        toolTipContent: null,
      });
      for (let iy = 0; iy < queryResult.axisY.length; iy += 1) {
        datasets[queryResult.axisX.length].data.push(0);
        datasets[queryResult.axisX.length].share.push(ySums[iy]);
        datasets[queryResult.axisX.length].isSum.push(true);
        datasets[queryResult.axisX.length].backgroundColor.push('transparent'); // Hide in legend
      }
    }

    const dataParam = {
      label: '',
      labels: yLabels,
      datasets,
    };
    const yLabelString = box.valueUnit !== null
      ? getValueUnitLabel(box.valueUnit)
      : '';
    const yLabel = yLabelString !== ''
      ? {
        scaleLabel: {
          display: true,
          labelString: yLabelString,
        },
      } : {};
    const options : Chart.ChartOptions = {
      animation: {
        // Especially the pie chart animation is to heavy and disable
        // for bar chart too to be consistent.
        duration: 0,
      },
      hover: {
        axis: 'xy',
      },
      scales: {
        xAxes: [{
          stacked: true,
          ...yLabel,
          ticks: {
            beginAtZero: true,
            callback: (value : any, index : any, values : Array<any>) => formatScaledValue(
              value,
              box.valueDecimals,
              box.valueFormatter,
            ),
            suggestedMax: showXSum
              ? scaleValue(xAxisMax * 1.05, box.valueFormatter) ?? undefined
              : undefined,
            max: query.shareOutput === Axis.x ? 100.0 : undefined,
          },
        }],
        yAxes: [{ stacked: true }],
      },
      legend: {
        display: queryResult.axisX.length > 1,
        position: 'bottom',
        align: 'center',
        onClick: () => {},
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem : Array<any>, data : any) => {
            const label = yLabels[tooltipItem[0].index];
            if (queryResult.axisX.length <= 1) { // skip sum if there is only one value
              return label;
            }
            const sum = formatValue(ySums[tooltipItem[0].index], box.valueFormatter, box.valueDecimals);
            const unit = yLabelString !== '' ? ` ${yLabelString}` : '';
            return `${label}: ${sum}${unit}`;
          },
          label: (tooltipItem : any, data : any) => {
            if (tooltipItem.datasetIndex >= queryResult.axisX.length) { // exclude sum hidden data from tooltip
              return '';
            }
            let label = xLabelsFull[tooltipItem.datasetIndex] || '';
            if (label !== '') {
              label += ': ';
            }
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            label += formatScaledValue(value, box.valueDecimals, box.valueFormatter);
            if (yLabelString !== '') {
              label += ` ${yLabelString}`;
            }
            return label;
          },
        },
      },
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          anchor: (context: Context) => (datasets[context.datasetIndex].isSum[context.dataIndex] ? 'start' : 'center'),
          align: (context: Context) => (datasets[context.datasetIndex].isSum[context.dataIndex] ? 'right' : 'center'),
          font: (context) => ({
            weight: datasets[context.datasetIndex].isSum[context.dataIndex] ? 'bold' : 'normal',
            size: datasets[context.datasetIndex].isSum[context.dataIndex] ? 16 : 12,
          }),
          formatter: (value, context) => {
            if (datasets[context.datasetIndex].isSum[context.dataIndex]) {
              return formatValue(ySums[context.dataIndex], box.valueFormatter, box.valueDecimals);
            }
            return formatValue(
              datasets[context.datasetIndex].share[context.dataIndex],
              ResultFormatter.decToPercent,
              0,
            );
          },
          color: (context) => textColor[context.datasetIndex],
          display: (context) => queryResult.axisX.length > 1 || datasets[context.datasetIndex].isSum[context.dataIndex],
        },
      },
    };
    this.renderChart(dataParam, options);
  }
}

