/* eslint-disable import/prefer-default-export */
import Unwatcher from '@/utils/unwatcher';
import { Store } from 'vuex';
import { RootState } from './root-state';

/**
 * Register callback as watch callbacks on the store
 * states / getters so it is called any time some filter
 * etc. changes that would affect the query and thus require
 * a rew result query being sent to travelviewer-api.
 * @param store this.$store from a Component
 * @param unwatcher Unwatcher instance.
 * @param callback The callback to call. First parameter will be supplied
 *   with a source string that can be used for debugging purposes that detail
 *   which watch it was that triggered the callback. Second parameter is new
 *   state and third old state
 */
export function watchStoreResendResultQuery(
  store: Store<RootState>,
  unwatcher: Unwatcher,
  callback: (source: string, oldState: any, newState: any) => void,
) {
  // When filter state changes => new fetch
  unwatcher.push(
    store.watch(
      (state: any, getters: any) => getters['filter/filterState'],
      (newState, oldState) => callback('filter', newState, oldState),
    ),
  );

  // When aggregation state changes => new fetch
  unwatcher.push(
    store.watch(
      (state: any, getters: any) => getters['modeActivity/aggregatedState'],
      (newState, oldState) => callback('aggregated modes or activities', newState, oldState),
    ),
  );

  // When query-settings state changes => new fetch
  unwatcher.push(
    store.watch(
      (state: any, getters: any) => getters['querySettings/querySettingsState'],
      (newState, oldState) => callback('query settings', newState, oldState),
    ),
  );
}
