import { Query, QueryResult, QueryResultAxisItem } from '@/store/modules/query/types';
import palette from '@/utils/palette';
import { DataColor, ResultFormatter, ResultUnit } from '@/store/modules/result-board/types';
import i18n from '@/i18n';
import { AggregatedMode, ModeActivityStateInterface } from '../store/modules/mode-activity/types';
import Log from './log';

/* eslint import/prefer-default-export: ["off", {}}] */

export type ColorSet = {
  bgColor: string;
  textColor: string;
}

/**
 * Get color from grey scale palette
 * @param ix column index
 * @param n number of colors of the palette to use. -1 for all
 * @returns
 */
function greyScale(ix : number, n : number = -1): ColorSet {
  const colors = ['grey-a', 'grey-b', 'grey-c', 'grey', 'grey-d'];
  const textColors = ['white', 'white', 'black', 'black', 'black'];
  const idx = ix % (n < 0 ? colors.length : Math.min(colors.length, n));
  return {
    bgColor: palette[colors[idx]],
    textColor: palette[textColors[idx]],
  };
}

/**
 * Get color from generic color scale palette
 * @param ix column index
 * @param n number of colors of the palette to use. -1 for all
 * @returns
 */
function genericColorScale(ix : number, n : number = -1): ColorSet {
  // eslint-disable-next-line no-multi-spaces, max-len
  const colors =     ['1c', '2c', '3c', '4c', '5c',    '1',  '2',  '3',  '4',  '5',   '1b', '2b', '3b', '4b', '5b',   '1d', '2d', '3d', '4d', '5d',  '1a', '2a', '3a', '4a', '5a'];
  // eslint-disable-next-line no-multi-spaces, max-len
  const textColors = ['1a', '2a', '3a', '4a', '5a',    '1d', '2a', '3a', '4a', '5a',  '1c', '2c', '3c', '4c', '5c',   '1b', '2b', '3b', '4b', '5b',  '1c', '2c', '3c', '4c', '5c'];
  const idx = ix % (n < 0 ? colors.length : Math.min(colors.length, n));
  return {
    bgColor: palette[colors[idx]],
    textColor: palette[textColors[idx]],
  };
}

const fallbackGrey = {
  bgColor: palette['grey-b'],
  textColor: palette.white,
};

/**
 * Resolve color from custom palette
 * @param ix Index on axis
 * @param axis Axis data
 * @param customPalette The custom palette (see ResultBox.customPalette for definition)
 * @returns ColorSet
 */
export function getCustomPaletteColor(
  ix: number,
  axis: Array<QueryResultAxisItem>,
  customPalette: Array<DataColor>|any,
): ColorSet {
  if (Array.isArray(customPalette)) {
    const idx = ix % customPalette.length;
    return {
      bgColor: customPalette[idx].bgColor,
      textColor: customPalette[idx].textColor,
    };
  } else if (customPalette !== null && typeof customPalette === 'object') {
    const key = axis[ix].id;
    if (customPalette[key] !== undefined) {
      return {
        bgColor: customPalette[key].bgColor,
        textColor: customPalette[key].textColor,
      };
    }
    return fallbackGrey;
  } else if (customPalette !== null) {
    // Not null, but not an array and not a object => invalid data
    console.warn('invalid custom palette data');
    return fallbackGrey;
  }
  // Should not happen if customPalette is of valid data type
  console.warn('customPalette should not be null');
  return fallbackGrey;
}

/**
 * Get color to use for data at position ix on x axis.
 * If data on x axis doesn't have a specific color, ''
 * is returned.
 */
export function getAxisXColor(
  ix : number,
  queryResult : QueryResult,
  query : Query,
  modeActivityState : ModeActivityStateInterface,
  customPalette : null|Array<DataColor>|any,
) : ColorSet {
  const axisX = query.axisX;
  if (customPalette !== null) {
    return getCustomPaletteColor(ix, queryResult.axisX, customPalette);
  } else if (axisX === '"aggregated mode"') {
    const agMode = modeActivityState.aggregatedModes.find(
      (am : AggregatedMode) => am.id === queryResult.axisX[ix].id,
    );
    if (agMode !== undefined) {
      return {
        bgColor: agMode.bgColor,
        textColor: agMode.textColor,
      };
    }
  } else if (axisX === '"aggregated activity"') {
    return genericColorScale(ix);
  } else if (axisX.startsWith('sampleVariable')) {
    // const colors = ['1', '2', '3', '4', '5', '1b', '2b', '3b', '4b', '5b'];
    if (queryResult.axisX[ix].id !== null) {
      return genericColorScale(ix);
    } else {
      // 'Missing answer' option
      return {
        bgColor: palette.grey,
        textColor: palette.black,
      };
    }
  } else if (axisX === '"week days"') {
    // Weekday colors?
    return genericColorScale(ix, 1);
  } else if (['"from zone"', '"to zone"'].indexOf(axisX) !== -1) {
    if (queryResult.axisX[ix].id === -1) {
      // External
      return {
        bgColor: palette.grey,
        textColor: palette.black,
      };
    } else {
      return genericColorScale(ix);
    }
  }

  return genericColorScale(0, 1);
}

export function getAxisYColor(
  iy : number,
  queryResult : QueryResult,
  query : Query,
  modeActivityState : ModeActivityStateInterface,
) : ColorSet {
  const axisY = query.axisY;
  if (['"from zone"', '"to zone"'].indexOf(axisY) !== -1) {
    if (queryResult.axisY[iy].id === -1) {
      // External
      return {
        bgColor: palette.grey,
        textColor: palette.black,
      };
    } else {
      return genericColorScale(iy);
    }
  }

  return {
    bgColor: palette['grey-b'],
    textColor: palette.white,
  };
}

/* eslint operator-assignment: ["off", {}}] */
/**
 * Scale a value according to given format. Eg. seconds => minutes
 *
 * This method is not only useful for graphs, but all type of result boxes.
 * @param value Value to format
 * @param format null or a ResultFormatter value
 */
export function scaleValue(
  value : null|number,
  format : null|ResultFormatter,
) : null|number {
  if (value === null) {
    return null;
  }
  let v = value;

  if (format !== null) {
    switch (format) {
      case ResultFormatter.mToKm:
        v = v / 1000;
        break;
      case ResultFormatter.sToMinutes:
        v = v / 60;
        break;
      case ResultFormatter.sToHours:
        v = v / 3600;
        break;
      case ResultFormatter.decToPercent:
        v = v * 100;
        break;
      default:
        throw new Error('Unexpected result formatter');
    }
  }

  return v;
}

/**
 * Format a value that has already been scaled using scaleValue()
 * @param value Result from scaleValue()
 * @param decimals box.valueDecimals
 * @param formatter box.valueFormatter
 */
export function formatScaledValue(
  value : null|number,
  decimals : number,
  formatter: null|ResultFormatter,
) : string {
  let v = value;
  if (v === null) {
    return '';
  }

  if (formatter === ResultFormatter.decToPercent) {
    const percentFormatter = new Intl.NumberFormat(i18n.locale, {
      style: 'percent',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
    return percentFormatter.format(value! / 100.0);
  }

  if (decimals > 0) {
    const p = 10 ** decimals; // 10 ^ decimals
    v = Math.round(v * p) / p;
  } else if (decimals < 0) {
    const p = 10 ** -decimals; // 10 ^ -decimals
    v = Math.round(v / p) * p;
  } else { // 0 decimals
    v = Math.round(v);
  }

  const options : Intl.NumberFormatOptions = {};
  if (decimals > 0) {
    options.minimumFractionDigits = decimals;
    options.maximumFractionDigits = decimals;
  }
  const r = Intl.NumberFormat(i18n.locale, options).format(v);

  Log.log('value', value, v, decimals, r);

  return r;
}

/* eslint operator-assignment: ["off", {}}] */
/**
 * Format a value according to given format and number of decimals. Uses local decimal
 * separator according to current VueI18n locale.
 *
 * This method is not only useful for graphs, but all type of result boxes.
 * @param value Value to format
 * @param format null or a ResultFormatter value
 * @param decimals > 0 to indicate number of decimals or < 0 to indicate rounding off value.
 * @example formatValue(253.73, null, 1) -> '253.7'
 * @example formatValue(253.73, null, -1) -> '250'
 * @example formatValue(253.73, null, 0) -> '253'
 */
export function formatValue(
  value : null|number,
  format : null|ResultFormatter,
  decimals : number,
) : string {
  const v = scaleValue(value, format);
  return formatScaledValue(v, decimals, format);
}

export function getValueUnitLabel(unit: ResultUnit): string {
  switch (unit) {
    case ResultUnit.minutes:
      return i18n.tc('unit.minutes');
    case ResultUnit.hours:
      return i18n.tc('unit.hours');
    case ResultUnit.km:
      return i18n.tc('unit.kilometers');
    case ResultUnit.kg:
      return i18n.tc('unit.kg');
    default:
      return '';
  }
}
