var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tv-box"},[_c('div',{staticClass:"toolbar"},[_c('b-field',_vm._l((_vm.mapStyles),function(ms){return _c('b-radio-button',{key:ms,attrs:{"native-value":ms,"type":"is-dark"},model:{value:(_vm.mapStyle),callback:function ($$v) {_vm.mapStyle=$$v},expression:"mapStyle"}},[_c('span',[_vm._v(_vm._s(_vm.mapStyleLabels[ms]))])])}),1)],1),_c('div',{staticClass:"map-stack"},[_c('map-mercartor-component',{attrs:{"mapFilter":_vm.mapFilter,"mapStyle":_vm.mapStyle}}),_c('div',{staticClass:"legend"},[_c('map-legend-component',{attrs:{"mapStyle":_vm.mapStyle}})],1)],1),(
      !_vm.hasMapData
      || _vm.requester.sendId !== null
      || _vm.requester.loadError
      || (_vm.requester.result !== null && (
        _vm.requester.result.mapFilter === null || _vm.requester.result.mapFilter.id === null
      ))
    )?_c('div',{staticClass:"overlay",class:_vm.requester.sendId !== null ? 'state-loading' : 'state-load-error'},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.hasMapData && _vm.requester.sendId !== null}}),(!_vm.hasMapData)?_c('div',{staticClass:"load-error-text"},[_c('h3',[_vm._v(_vm._s(_vm.$t('map.no-map-data-error.title')))]),_c('p',{staticClass:"support-info"},[_vm._v(" "+_vm._s(_vm.$t('map.no-map-data-error.text'))+" ")]),_c('b-button',{attrs:{"type":"is-dark","rounded":"","tag":"a","href":_vm.flavorData.supportUrl}},[_vm._v(_vm._s(_vm.flavorData.name)+" Support")])],1):(
        _vm.requester.sendId === null &&
        (_vm.requester.loadError || (
          _vm.requester.result !== null && (_vm.requester.result.mapFilter === null || _vm.requester.result.mapFilter.id === null)
        ))
      )?_c('div',{staticClass:"load-error-text"},[_c('h3',[_vm._v(_vm._s(_vm.$t('map.load-filter-error')))]),_c('b-button',{attrs:{"type":"is-assertive","rounded":"","icon-left":"reload"},on:{"click":function($event){$event.stopPropagation();return _vm.onReload($event)}}},[_vm._v(" "+_vm._s(_vm.$t('nav.reload'))+" ")])],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }