


















































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
// @ is an alias to /src
import ViewLayoutComponent from '@/components/ViewLayoutComponent.vue';
import InfoBoxComponent from '@/components/InfoBoxComponent.vue';
import DataSetDescriptionComponent from '@/components/DataSetDescriptionComponent.vue';
import ResultBoxComponent from '@/components/ResultBoxComponent.vue';
import MapResultComponent from '@/components/map/MapResultComponent.vue';
import ResultBoardSectionHeadingComponent from '@/components/ResultBoardSectionHeadingComponent.vue';
import ExpandableDescriptionComponent from '@/components/ExpandableDescriptionComponent.vue';
import Log from '@/utils/log';
import { ResultBoard, ResultBox, ResultVisual } from '@/store/modules/result-board/types';
import Unwatcher from '@/utils/unwatcher';
import { Flavor } from '@/flavor/types';
import { flavor, flavorData } from '@/flavor';
import { read } from 'fs';
import ResultBoardInfoComponent from '@/components/ResultBoardInfoComponent.vue';
import { RootState } from '../store/root-state';

type Grid = {
  key : string;
  rows : Array<Array<ResultBox>>; // Indexing of grid is [y][x] !
}

function buildGrid(key: string, resultBoard : ResultBoard) : Grid {
  const grid : Array<Array<ResultBox>> = [];

  // Build a simple 2 wide grid
  let row = [];
  let rowCols = 0;
  for (let i = 0; i < resultBoard.resultBoxes.length; i += 1) {
    const rb = resultBoard.resultBoxes[i];

    if (rowCols + rb.colspan > 12) {
      grid.push(row);
      row = [];
      rowCols = 0;
    }
    rowCols += rb.colspan;
    row.push(rb);
  }

  if (row.length > 0) {
    grid.push(row);
  }

  return {
    key,
    rows: grid,
  };
}

@Component({
  components: {
    ViewLayoutComponent,
    InfoBoxComponent,
    DataSetDescriptionComponent,
    ResultBoxComponent,
    ResultBoardInfoComponent,
    ResultBoardSectionHeadingComponent,
    ExpandableDescriptionComponent,
    MapResultComponent,
  },

  methods: {
    getResultBoardStyle() {
      return 'red';
    },
  },
})
export default class AnalysisView extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  resultBoardId : string|null = null;
  resultBoards : Array<ResultBoard> = [];
  grid : Grid = { key: 'undefined', rows: [] };
  nRequiresTrips : boolean = false;
  flavor: Flavor = flavor;

  public async created() {
    const self = this;

    // When filter state changes => new fetch
    this.unwatcher.push(this.$store.watch(
      (state: RootState, getters: any) => state.resultBoard.resultBoards,
      (newValue: ResultBoard[]|null, _oldValue: any|null) => {
        Log.log('watch resultboard trigger');
        if (self.resultBoardId === null && newValue !== null && newValue.length > 0) {
          self.resultBoardId = newValue[0].id;
        }
        self.resultBoards = newValue !== null ? newValue : [];
        self.onChangeResultBoard(); // rebuild grid
      },
    ));

    this.unwatcher.push(this.$store.watch(
      (state: RootState, getters: any) => state.querySettings.nRequiresTrips,
      (newValue: any|null, _oldValue: any|null) => {
        Log.log('watch nRequiresTrips trigger');
        self.nRequiresTrips = newValue;
      },
    ));
  }

  public beforeDestroy() {
    this.unwatcher.unwatchAll();
  }

  data() {
    const { resultBoards } = this.$store.state.resultBoard;
    Log.log(resultBoards);

    return {
      resultBoardId: resultBoards.length > 0 ? resultBoards[0].id : null,
      resultBoards,
      grid: resultBoards.length > 0 ? buildGrid(resultBoards[0].id, resultBoards[0]) : [],
      nRequiresTrips: this.$store.state.querySettings.nRequiresTrips,
      sectionHeadingVisual: ResultVisual.sectionHeading,
    };
  }

  onChangeResultBoard() {
    let grid : Grid = {
      key: 'undefined',
      rows: [],
    };
    if (this.resultBoardId !== null) {
      const selectedBoard = this.resultBoards.find((b : ResultBoard) => b.id === this.resultBoardId);
      if (selectedBoard !== undefined) {
        grid = buildGrid(this.resultBoardId, selectedBoard);
      }
    }
    this.grid = grid;
  }

  onChangeNRequireTrips() {
    Log.log(`set nRequriesTrips to ${this.nRequiresTrips} in store`);
    this.$store.commit('querySettings/set', {
      nRequiresTrips: this.nRequiresTrips,
    });
  }
}

