




























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ResultVisual } from '@/store/modules/result-board/types';
import { SetVisualParams, SetZoneFileParams } from '@/store/modules/result-board/index';
import { Flavor } from '@/flavor/types';
import { flavor } from '@/flavor';
import { watchStoreResendResultQuery } from '@/store/watch';
import Unwatcher from '@/utils/unwatcher';
import QueryRequester from '@/utils/queryRequester';
import Log from '@/utils/log';
import ResultTableComponent from './ResultTableComponent.vue';
import ResultPieChartComponent from './ResultPieChartComponent.vue';
import ResultBarChartComponent from './ResultBarChartComponent.vue';
import ResultHorizontalBarChartComponent from './ResultHorizontalBarChartComponent.vue';
import ResultFactTextComponent from './ResultFactTextComponent.vue';
import OdFilePickerComponent from './OdFilePickerComponent.vue';
import QueryLegendComponent from './QueryLegendComponent.vue';

@Component({
  props: {
    box: {}, // ResultBox
    resultBoardId: {}, // string
  },
  components: {
    ResultTableComponent,
    ResultPieChartComponent,
    ResultBarChartComponent,
    ResultHorizontalBarChartComponent,
    ResultFactTextComponent,
    OdFilePickerComponent,
    QueryLegendComponent,
  },
})
export default class ResultBoxComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  requester : QueryRequester = new QueryRequester(this.$store)
  showOdMatrixPicker : boolean = false;
  isExpanded : boolean = false;
  expandedTab : number = 0; // selected tab in expanded view
  visualType : null|ResultVisual = null;
  flavor : Flavor = flavor;
  travelViewer = Flavor.travelViewer;

  data() {
    return {
      showOdMatrixPicker: this.getShowOdMatrixPicker(),
      tableVisual: ResultVisual.table,
      pieChartVisual: ResultVisual.pieChart,
      barChartVisual: ResultVisual.barChart,
      horizontalBarChartVisual: ResultVisual.horizontalBarChart,
      lineChartVisual: ResultVisual.lineChart,
      factTextVisual: ResultVisual.factText,
      odMatrixVisual: ResultVisual.odMatrix,
      isExpanded: false,
      visualType: this.$props.box.type,
    };
  }

  public async created() {
    const self = this;

    const watchChangedFn = (
      source : string, newStoreValue: Array<number>|null, oldStoreValue: Array<number>|null,
    ) => {
      if (newStoreValue !== oldStoreValue) {
        self.showOdMatrixPicker = this.getShowOdMatrixPicker();
        if (!self.showOdMatrixPicker) {
          Log.log(`${source} change => fetch`);
          self.fetch().catch(() => {});
        }
      }
    };

    watchStoreResendResultQuery(
      this.$store,
      this.unwatcher,
      (source, newState, oldState) => watchChangedFn(source, newState, oldState),
    );

    // When box state changes => new fetch
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => getters['resultBoard/boxState'],
      (newStoreValue: any|null, oldStoreValue: any|null) => {
        const key = `${this.$props.resultBoardId}:${this.$props.box.id}`;
        const newBoxState = newStoreValue !== null ? newStoreValue[key] : null;
        const oldBoxState = oldStoreValue !== null ? oldStoreValue[key] : null;
        watchChangedFn('boxState', newBoxState, oldBoxState);
      },
    ));

    // Initial fetch of data
    // Don't perform initial fetch if filter has not been initialized yet
    if (self.$store.state.filter.aggregatedModes.length > 0
      && self.$store.state.filter.aggregatedActivities.length > 0
      && !self.getShowOdMatrixPicker()
    ) {
      Log.log('initial load (with filters loaded) => fetch');
      self.fetch().catch(() => {});
    }
  }

  beforeDestroy() {
    this.unwatcher.unwatchAll();
  }

  /* eslint prefer-destructuring: ["off", {}}] */
  /**
   * Fetch QueryResult for the result box partial query
   * and current filter state.
   *
   * During query this.requester.sendId will be non-null and when query
   * is completed it is set back to null and result is updated.
   *
   * Rejects upon request error.
   */
  async fetch() {
    await this.requester.request(this.$props.box.partialQuery, this.$props.box.filterOverride);
  }

  onReload() {
    if (!this.getShowOdMatrixPicker()) {
      this.fetch().catch(() => {});
    }
  }

  onExpand() {
    if (!this.isExpanded && this.requester.result !== null && !this.requester.loadError) {
      this.isExpanded = true;
    }
  }

  onCloseExpand() {
    Log.log('close expand');
    if (this.isExpanded) {
      this.isExpanded = false;
    }
  }

  onVisualTypeChange() {
    this.$store.commit('resultBoard/setBoxVisual', {
      resultBoardId: this.$props.resultBoardId,
      boxId: this.$props.box.id,
      type: this.visualType,
    } as SetVisualParams);
  }

  onRemoveZoneFile() {
    this.onCloseExpand();
    this.$store.commit('resultBoard/setBoxZoneFile', {
      resultBoardId: this.$props.resultBoardId,
      boxId: this.$props.box.id,
      zoneFile: null,
    } as SetZoneFileParams);
    this.requester.clearResult();
  }

  getShowOdMatrixPicker() {
    const zoneFile = this.$props.box.partialQuery.zoneFile;
    return this.$props.box.type === ResultVisual.odMatrix
      && (zoneFile === null || zoneFile === undefined);
  }
}
