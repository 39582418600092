







import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Pie } from 'vue-chartjs';
import { Query, PartialQuery, QueryResult } from '@/store/modules/query/types';
import resolveLabel from '@/utils/I18nLabel';
import { formatValue } from '@/utils/graphHelper';
import { AggregatedMode } from '../store/modules/mode-activity/types';
import query from '../api/query';
import { ResultBox } from '../store/modules/result-board/types';

@Component({
  props: {
    queryResult: {},
    query: {},
    box: {}, // ResultBox
  },
})
export default class ResultFactTextComponent extends Vue {
  data() {
    const queryResult : QueryResult = this.$props.queryResult;
    const box : ResultBox = this.$props.box;

    const value = formatValue(queryResult.cells[0][0].value, box.valueFormatter, box.valueDecimals);
    return {
      value,
    };
  }
}
