

















import { Component, Vue } from 'vue-property-decorator';
import palette from '@/utils/palette';
import { Flavor } from '@/flavor/types';
import { flavor } from '@/flavor';

@Component({
  props: {
    info: {}, // ResultBoardInfo,
  },
  computed: {
    headerStyle() {
      const index = this.$props.info.palette;
      const bgColor = palette[`${index}d`];
      return `background-color:${bgColor}`;
    },
    imageLabelColorStyle() {
      const index = this.$props.info.palette;
      const bgColor = palette[`${index}a`];
      return `color:${bgColor}`;
    },
  },

})
export default class ResultBoardInfoComponent extends Vue {
  flavor : Flavor = flavor;
}

