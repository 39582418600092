





















import { Component, Vue } from 'vue-property-decorator';
import Unwatcher from '@/utils/unwatcher';
import palette from '@/utils/palette';
import { apiUrl } from '@/env';
import Log from '@/utils/log';
import { ModeActivityStateInterface, AggregatedMode } from '@/store/modules/mode-activity/types';
import resolveLabel from '@/utils/I18nLabel';
import { MapStyle } from './MapMercartorComponent.vue';

interface LegendEntry {
  id: number;
  color: string;
  label: string;
}

@Component({
  props: {
    mapStyle: {}, // MapStyle enum
  },
})
export default class MapLegendComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  legend : Array<LegendEntry> = [];
  dark : boolean = false;

  public mounted() {
    this.unwatcher.push(this.$watch(() => this.$props.mapStyle, this.update));

    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => getters['modeActivity/aggregatedState'],
      this.update,
    ));

    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => state.filter.aggregatedModes,
      this.update,
    ));

    this.update();
  }

  public beforeUnmount() {
    this.unwatcher.unwatchAll();
  }

  private update() {
    this.dark = this.$props.mapStyle === MapStyle.dark;
    this.legend = [];
    const modeActivity : ModeActivityStateInterface = this.$store.state.modeActivity;
    const enabledAm : Array<number> = this.$store.state.filter.aggregatedModes;
    modeActivity.aggregatedModes.filter((am) => enabledAm.includes(am.id)).forEach((am: AggregatedMode) => {
      this.legend.push({
        id: am.id,
        color: am.bgColor,
        label: resolveLabel(null, am.labelTranslations),
      });
    });
  }
}
