

























































import { Component, Vue } from 'vue-property-decorator';
import { QueryResultAxisItem, QueryResultCell } from '@/store/modules/query/types';
import { ResultBox, ResultFormatter } from '@/store/modules/result-board/types';
import resolveLabel, { axisTranslation } from '@/utils/I18nLabel';
import {
  getAxisXColor,
  getAxisYColor,
  scaleValue,
  formatScaledValue,
  ColorSet,
} from '@/utils/graphHelper';
import Log from '@/utils/log';

interface TableCell {
  scaledValue : number|null;
  formattedValue : string;
  n : number;
}

@Component({
  props: {
    queryResult: {},
    query: {},
    box: {},
    isExpanded: {}, // boolean - is result box expanded?
  },
})
export default class ResultTableComponent extends Vue {
  private cells : Array<Array<TableCell>> = [];
  private xLabels : Array<string> = [];
  private yLabels : Array<string> = [];

  data() {
    const box : ResultBox = this.$props.box;

    const xLabels = this.$props.queryResult.axisX.map(
      (x : QueryResultAxisItem) : string => resolveLabel(x.label, x.labelTranslations),
    );
    const yLabels = this.$props.queryResult.axisY.map(
      (y : QueryResultAxisItem) : string => resolveLabel(y.label, y.labelTranslations),
    );
    const xColors = this.$props.queryResult.axisX.map(
      (x : QueryResultAxisItem, index : number) : ColorSet => getAxisXColor(
        index, this.$props.queryResult, this.$props.query, this.$store.state.modeActivity, box.customPalette,
      ),
    );
    const yColors = this.$props.queryResult.axisY.map(
      (y : QueryResultAxisItem, index : number) : ColorSet => getAxisYColor(
        index, this.$props.queryResult, this.$props.query, this.$store.state.modeActivity,
      ),
    );
    const cells : Array<Array<TableCell>> = [];
    this.$props.queryResult.cells.forEach((row : Array<QueryResultCell>, ix : number) => {
      const tableRow : Array<TableCell> = [];
      row.forEach((cell : QueryResultCell, iy : number) => {
        const sv = scaleValue(cell.value, box.valueFormatter);
        const fv = formatScaledValue(sv, box.valueDecimals + 2, box.valueFormatter);
        tableRow.push({
          scaledValue: sv,
          formattedValue: fv,
          n: cell.n,
        });
      });
      cells.push(tableRow);
    });

    const showAxisLabels = xLabels.length > 1 && yLabels.length > 1;
    const showXHead = this.$props.isExpanded // b-tooltip breaks if there is no x head
      || showAxisLabels
      || xLabels.length > 1
      || (xLabels.length > 0 && xLabels[0] !== '');
    const showYHead = showAxisLabels || yLabels.length > 1 || (yLabels.length > 0 && yLabels[0] !== '');
    return {
      showAxisLabels,
      showXHead,
      showYHead,
      axisXLabel: showAxisLabels ? axisTranslation(this.$props.query.axisX) : '',
      axisYLabel: showAxisLabels ? axisTranslation(this.$props.query.axisY) : '',
      xLabels,
      yLabels,
      cells,
      xColors,
      yColors,
    };
  }

  /**
   * Writes a tab delimitted table to clipboard with scaled values
   * without decimal crop
   */
  onCopy() {
    let s = '';

    const hasXLabels = this.xLabels.length > 1 || this.xLabels[0] !== '';
    const hasYLabels = this.yLabels.length > 1 || this.yLabels[0] !== '';

    if (this.cells.length > 0) {
      if (hasXLabels) {
        this.xLabels.forEach((label : string, ix : number) => {
          if (ix > 0 || hasYLabels) {
            s += '\t';
          }
          s += label.replace('\t', '').replace('\n', '').replace('\r', '');
        });
        s += '\n';
      }
      for (let iy = 0; iy < this.cells[0].length; iy += 1) {
        if (hasYLabels) {
          s += this.yLabels[iy];
        }
        // Add cells
        for (let ix = 0; ix < this.cells.length; ix += 1) {
          if (ix > 0 || hasYLabels) {
            s += '\t'; // when yLabels is empty, don't add tab before first cell
          }
          let sv : number|null = this.cells[ix][iy].scaledValue;
          if (sv !== null && this.$props.box.valueFormatter === ResultFormatter.decToPercent) {
            // If value formatter is decimal to percent, revert value scaling and export in decimal
            sv /= 100.0;
          }
          if (sv !== null) {
            s += Intl.NumberFormat(this.$i18n.locale, {
              useGrouping: false,
            }).format(sv);
          }
        }
        // No EOL on last line makes Excel happy
        if (iy < this.cells[0].length - 1) {
          s += '\n';
        }
      }
    }

    try {
      navigator.clipboard.writeText(s);
      this.$buefy.toast.open({
        message: this.$t('resultbox.copied-to-clipboard').toString(),
        type: 'is-balanced',
        position: 'is-bottom',
      });
    } catch (e) {
      this.$buefy.toast.open({
        message: 'Browser rejected',
        type: 'is-assertive',
        position: 'is-bottom',
      });
    }
  }
}
