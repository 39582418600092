






import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    box: {}, // ResultBoardSectionBox
  },
})
export default class ResultBoardSectionComponent extends Vue {

}
