

















import { Component, Vue } from 'vue-property-decorator';
import Unwatcher from '@/utils/unwatcher';
import { SetZoneFileParams } from '@/store/modules/result-board';
import { Attachment } from '@/store/modules/query/types';

function arrayBufferToBase64(buffer : ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

@Component({
  props: {
    box: {}, // ResultBox
    resultBoardId: {}, // string
  },
  components: {},
})
export default class OdMatrixComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  loadError : boolean = false;

  data() {
    return {
      file: this.$props.box.partialQuery.zoneFile,
    };
  }

  beforeDestroy() {
    this.unwatcher.unwatchAll();
  }

  onFileSelect(value : any) {
    // Read file from disk and assign content to partialQuery
    const r = new FileReader();
    r.onload = (e) => {
      const zoneFile = {
        fileName: value.name,
        content: arrayBufferToBase64(e.target?.result as ArrayBuffer),
      } as Attachment;
      this.$store.commit('resultBoard/setBoxZoneFile', {
        resultBoardId: this.$props.resultBoardId,
        boxId: this.$props.box.id,
        zoneFile,
      } as SetZoneFileParams);
    };
    r.onerror = (ev) => {
      this.$buefy.dialog.alert({
        message: `Failed to load file ${value.name}`,
        type: 'is-assertive',
        container: 'main',
      } as any); // any: container field is missing in buefy
    };
    r.readAsArrayBuffer(value);
  }
}
