
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Bar, HorizontalBar } from 'vue-chartjs';
import { Query, PartialQuery, QueryResult } from '@/store/modules/query/types';
import resolveLabel from '@/utils/I18nLabel';
import {
  getAxisXColor, scaleValue, formatValue, formatScaledValue,
} from '@/utils/graphHelper';
import i18n from '@/i18n';
import Chart from 'chart.js';
import { AggregatedMode } from '../store/modules/mode-activity/types';
import { ResultBox, ResultFormatter } from '../store/modules/result-board/types';

@Component({
  props: {
    queryResult: {},
    query: {},
    box: {},
  },
})

export default class ResultBarChartComponent extends mixins(Bar) {
  mounted() {
    const box : ResultBox = this.$props.box;
    const queryResult : QueryResult = this.$props.queryResult;
    const query : Query = this.$props.query;
    const datasets : Array<any> = [];
    const labelsFull : Array<string> = [];
    const labelsShort : Array<string> = [];
    const textColor : Array<any> = [];
    for (let iy = 0; iy < queryResult.axisY.length; iy += 1) {
      datasets.push({
        data: [],
        backgroundColor: [],
        labels: [],
      });
    }
    const cells = queryResult.cells;
    for (let ix = 0; ix < cells.length; ix += 1) {
      const l = resolveLabel(queryResult.axisX[ix].label, queryResult.axisX[ix].labelTranslations);
      let lShort = l;
      if (l.length > 20) lShort = `${l.substr(0, 17)}...`;
      const color = getAxisXColor(ix, queryResult, query, this.$store.state.modeActivity, box.customPalette);
      for (let iy = 0; iy < cells[ix].length; iy += 1) {
        datasets[iy].data.push(scaleValue(cells[ix][iy].value, box.valueFormatter));
        datasets[iy].backgroundColor.push(color.bgColor);
        datasets[iy].labels.push(lShort);
        textColor.push(color.textColor);
      }
      labelsFull.push(l);
      labelsShort.push(lShort);
    }
    const dataParam = {
      label: '',
      labels: labelsShort,
      datasets,
    };
    let yLabel = {};
    if (box.valueFormatter !== null
      && [ResultFormatter.sToMinutes, ResultFormatter.mToKm].indexOf(box.valueFormatter) !== -1
    ) {
      yLabel = {
        scaleLabel: {
          display: true,
          labelString: box.valueFormatter === ResultFormatter.sToMinutes
            ? this.$t('unit.minutes') : this.$t('unit.kilometers'),
        },
      };
    }
    const options : Chart.ChartOptions = {
      animation: {
        // Especially the pie chart animation is to heavy and disable
        // for bar chart too to be consistent.
        duration: 0,
      },
      hover: {
        axis: 'x',
      },
      scales: {
        yAxes: [{
          stacked: true,
          ...yLabel,
          ticks: {
            beginAtZero: true,
            callback: (value : any, index : any, values : Array<any>) => formatScaledValue(
              value,
              box.valueDecimals,
              box.valueFormatter,
            ),
          },
        }],
      },

      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem : Array<any>, data : any) => '',
          label: (tooltipItem : any, data : any) => {
            let label = labelsFull[tooltipItem.index] || '';
            if (label !== '') {
              label += ': ';
            }
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            label += formatScaledValue(value, box.valueDecimals, box.valueFormatter);
            return label;
          },
        },
      },
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          formatter: (value, context) => formatScaledValue(value, box.valueDecimals, box.valueFormatter),
          color: (context) => textColor[context.dataIndex],
          display: false, // disable datalabel on bar chart for now (too cluttered)
        },
      },
    };
    this.renderChart(dataParam, options);
  }
}
