









import { Component, Vue } from 'vue-property-decorator';
import Unwatcher from '@/utils/unwatcher';
import palette from '@/utils/palette';
import { apiUrl } from '@/env';
import Log from '@/utils/log';

export enum MapStyle {
  app,
  light,
  dark,
  transparent,
}

interface LocationUpdate {
  id: number;
  centroid: Array<number>|null;
}

function colorToRGBArray(color: string): Array<number> {
  if (color.startsWith('rgb(')) {
    let s = color.substring(4);
    s = s.substring(0, s.length - 1);
    return s.split(',').map((p) => parseInt(p.trim(), 10));
  }
  if (color.startsWith('#')) {
    const r = [];
    for (let i = 1; i < color.length; i += 2) {
      r.push(parseInt(color.substring(i, i + 2), 16));
      if (r.length === 3) break;
    }
    return r;
  }

  return colorToRGBArray(palette.grey);
}

/** Resolve the url for Base map tiles */
function resolveBaseTileUrl(style: MapStyle): string {
  switch (style) {
    case MapStyle.app:
    case MapStyle.light:
      return 'https://api.maptiler.com/maps/b2a53de0-d4c1-459a-857c-3be28bfeb9dc/style.json?key=Errqp91ZNDebZvgxt5zS';
    case MapStyle.dark:
      return 'https://api.maptiler.com/maps/80c06cf9-e733-41b0-9db8-eac444e6f024/style.json?key=Errqp91ZNDebZvgxt5zS';
    case MapStyle.transparent:
    default:
      return '';
  }
}

/** Resolve the url for Data map tiles */
function resolveDataTileUrl(mapFilterId : string|undefined|null): string {
  // Send a empty url if there is no filter to trigger unloading the
  // data overlay in that case and not spill out errors of wrong filter parameter
  if (mapFilterId === null || mapFilterId === undefined || mapFilterId === '') {
    return '';
  }
  return `${apiUrl}/tiles/travel/{z}/{x}/{y}.pbf?filter=${mapFilterId}`;
}

/** Resolve url to load Mercartor map viewer */
function resolveMercartorUrl(style: MapStyle): string {
  const baseTileUrl = resolveBaseTileUrl(style);
  return `/mercartor/?baseTileUrl=${encodeURIComponent(baseTileUrl)}`;
}

@Component({
  props: {
    mapStyle: {}, // MapStyle enum
    mapFilter: {}, // MapFilter from query/types.ts
  },
})
export default class MapMercatorComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  url: string = resolveMercartorUrl(this.$props.mapStyle);
  locationUpdate : LocationUpdate = { id: 0, centroid: null };

  public mounted() {
    this.unwatcher.push(this.$watch(() => this.$props.mapFilter?.id, this.update));
    this.unwatcher.push(this.$watch(() => this.$props.mapStyle, this.update));
  }

  public beforeUnmount() {
    this.unwatcher.unwatchAll();
  }

  onIframeLoad() {
    this.update();
  }

  private update() {
    const colors = this.$store.getters['modeActivity/getModeColorMap'];
    const arrayColors: any = {};
    Object.keys(colors).forEach((modeId: string) => {
      arrayColors[modeId] = {
        bgColor: colorToRGBArray(colors[modeId].bgColor),
        textColor: colorToRGBArray(colors[modeId].textColor),
        borderColor: colorToRGBArray(colors[modeId].borderColor),
      };
    });
    if (this.locationUpdate.centroid === null
      && this.$props.mapFilter !== null && this.$props.mapFilter.centroid !== null
    ) {
      this.locationUpdate.centroid = this.$props.mapFilter.centroid;
      this.locationUpdate.id += 1;
    }
    const payload = {
      type: 'travelviewer-ui',
      baseTileUrl: resolveBaseTileUrl(this.$props.mapStyle),
      dataTileUrl: resolveDataTileUrl(this.$props.mapFilter?.id),
      location: this.locationUpdate,
      modeColors: arrayColors,
      fallbackModeColor: colorToRGBArray(palette.grey),
    };
    const target = `${document.location.protocol}//${document.location.host}/mercartor/`;
    Log.log('payload:', payload, 'target:', target);
    const iframe = this.$refs.iframe as HTMLIFrameElement;
    iframe.contentWindow!.postMessage(payload, target);
  }
}
