

































































import { Axis } from '@/store/modules/query/types';
import { shareOutputLabel } from '@/utils/I18nLabel';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    box: {}, // ResultBox
    query: {}, // ResultQuery
  },
})
export default class QueryLegendComponent extends Vue {
  // eslint-disable-next-line class-methods-use-this
  fnShareOutputLabel = shareOutputLabel;
}
